<!-- =========================================================================================
  File Name: 'ConfirmModal.vue'
  Description: 'modal for confirmation'
  ----------------------------------------------------------------------------------------
  Author: Nguyen Duy Toan
  Created Date: "2021-01-22"
  Modified Date: "2021-01-22"
========================================================================================== -->
<template>
  <div>
    <b-modal
      :id="id"
      centered
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-backdrop="isNoClose"
    >
      <div class="confirm-modal">
        <div :class="color === null ? 'text-warning' : color">
          <feather-icon :icon="icon" />
        </div>
        <div class="title">
          {{
            title === null ? 'Bạn chắc chắn xóa' : title
          }}
        </div>
        <div class="description">
          {{ content }}
        </div>
        <div class="action">
          <b-button
            variant="primary"
            class="mr-1 btn-left"
            @click="accept"
          >
            <!-- {{ $t("common.ok-title") }} -->
            Đồng ý
          </b-button>
          <b-button
            v-if="!isNotification"
            variant="outline-danger"
            @click="cancel"
          >
            <!-- {{ $t("common.cancel-title") }} -->
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BCardText, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    selectedItemId: {
      type: Number,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    action: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    color: {
      type: String,
      default: 'text-warning',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    accept() {
      this.$bvModal.hide(this.id)
      this.$emit('accept', this.selectedItemId, this.action)
    },
    cancel() {
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="scss">
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  svg {
    width: 80px;
    height: 80px;
  }
  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }
  .action {
    margin-top: 25px;
  }
  .description {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .confirm-modal {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .confirm-modal {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .btn-left {
        margin-right: 0px !important;
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="custom-btn">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :variant="variant"
      :disabled="disabled"
      class="btn-component cursor-pointer"
      :size="size"
      @click="changeBtn"
    >
      <feather-icon
        v-if="iconBtn !== null"
        :icon="iconBtn"
        class="icon-content mr-50"
      />
      <b-img
        v-if="src"
        class="img-style-icon mr-50"
        :src="src"
      />
      <span class="text-content align-middle">{{ contentBtn }}</span>
    </b-button>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    contentBtn: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    variant: {
      type: String,
      default: 'primary',
    },
    // eslint-disable-next-line vue/require-default-prop
    iconBtn: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    disabled: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    src: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    size: {
      type: String,
      default: '',
    },
  },
  methods: {
    changeBtn() {
      this.$emit('click')
    },
  },
}
</script>
<style lang="scss">
 .img-style-icon {
   width: 14px;
   height: 14px;
 }
</style>

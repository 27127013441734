<template>
  <div
    id="users"
    class="page-container-table"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm thiết bị'"
      :arrayExcel="[]"
      :hideDowload="false"
      :hideExportFile="false"
      :hideImportFile="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="()=>{}"
      @clickExportExcel="()=>{}"
      @importFile="()=>{}"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->

        <!-- Column:Chức năng -->
        <span v-if="props.column.field === 'ActionFunction'">
          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- thêm mới modal  -->
    <modal-create
      :id="modalIdCreate"
      :title="modalType==='add' ?'Thêm thiết bị ' : 'Chỉnh sửa thiết bị '"
      :dataDetail="dataDetail"
      :type="modalType"
      @accept="handleModal"
    />
    <!-- thêm mới modal  -->
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from './components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
// import dataFake from '../../dataFake'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    ModalCreate,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      arrayExcel: [],
      formatFullName,
      columns: [
        {
          label: 'TÊN THIẾT BỊ',
          field: 'equipment',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG',
          field: 'quantity',
          sortable: false,
        },
        {
          label: 'MÔ TẢ',
          field: 'description',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      dataDetail: {},
      idDetail: '',
      modalIdCreate: 'modal-id-create-use-device',
      modalIdEdit: 'modal-id-edit-use-device',
      dataList: [],

      // dataList: dataFake.data,
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_USE_EQUIP, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm vs xóa
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    // click show modal thêm vs xóa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_USE_EQUIP}${this.idDetail}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalIdCreate)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // thêm
    async handleModal(param) {
      if (this.modalType === 'add') {
        const payload = {
          ...param,
          quantity: parseInt(param.quantity, 10),
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.post(ConstantsApi.CREATE_USE_EQUIP, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalIdCreate)
            this.fetchData(this.urlQuery)
          }
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else if (this.modalType === 'edit') {
        const payload = {
          id: this.idDetail,
          ...param,
          quantity: parseInt(param.quantity, 10),
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.put(ConstantsApi.EDIT_USE_EQUIP, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalIdCreate)
            this.fetchData(this.urlQuery)
          }
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_USE_EQUIP, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
    // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-injury-management' })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>

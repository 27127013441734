<!-- =========================================================================================
  File Name: 'InputSearchComponent.vue'
  Description: 'component search'
  ----------------------------------------------------------------------------------------
  Author: Nguyen Duy Toan
  Created Date: "2021-01-23"
  Modified Date: "2021-01-23"
========================================================================================== -->
<template>
  <div class="input-search">
    <b-form-input
      v-model="keySearch"
      type="text"
      class="d-inline-block"
      :placeholder="placeholder"
      @keydown="handleInputChange"
    ></b-form-input>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'Search',
  components: {
    'b-form-input': BFormInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Tìm kiếm',
    },
  },
  data() {
    return {
      keySearch: '',
      timer: null,
    }
  },
  watch: {
    value(n, o) {
      this.keySearch = n
      // console.log(n)
    },
  },
  methods: {
    // value of input change
    handleInputChange(event) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$emit('change', event.target.value)
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

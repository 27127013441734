export default {
  // SỬ DỤNG THIẾT BỊ
  API_USE_EQUIP: 'UsingEquipment/using-equipments',
  CREATE_USE_EQUIP: 'UsingEquipment/add-using-equipment',
  EDIT_USE_EQUIP: 'UsingEquipment/update-using-equipment',
  DELETE_USE_EQUIP: 'UsingEquipment/delete-using-equipment',
  DETAIL_USE_EQUIP: 'UsingEquipment/using-equipment/',

  // danh sách thiết bị
  COMBOBOX: 'Equipment/get-combobox-equipment',
}

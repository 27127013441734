<template>
  <div>
    <b-input-group
      :disabled="disabled"
      class="date-picker-format mb-1"
    >
      <b-form-input
        v-model="dateFormat"
        :disabled="disabled"
        type="text"
        :placeholder="placeholder"
        autocomplete="off"
        show-decade-nav
        @change="inputChange"
      />
      <b-input-group-append :disabled="disabled">
        <b-form-datepicker
          v-model="date"
          :disabled="disabled"
          class="custome-datepicker"
          show-decade-nav
          button-variant="outline-primary"
          size="sm"
          button-only
          right
          aria-controls="example-input"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          :locale="lang==='en'?'en':'vi'"
          @context="onContext"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy',
    },
  },
  data() {
    return {
      date: this.value !== null ? this.value : '',
      dateFormat: '',
      isFirst: true,
      lang: this.$language(),
    }
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.date = newValue !== null ? newValue : ''
      }
    },
  },
  methods: {
    checkDayInput(date) {
      if (date.length === null || date.length === 0) {
        this.date = ''
        return true
      }
      const regex = /[!@#$&*/._-\s]/g
      if (!date.match(regex)) return false
      const dateFormat = date.replace(regex, '/')
      const strs = dateFormat.split('/')
      if (strs.length !== 3) return false
      const dateData = `${strs[2]}/${strs[1]}/${strs[0]}`
      const regexDay = /^(?:\d{4}\/(?:(?:(?:(?:[13578]|0[13578]|1[02])\/(?:[1-9]|0[1-9]|[1-2][0-9]|3[01]))|(?:(?:[4,6,9]|0[469]|11)\/(?:[1-9]|0[1-9]|[1-2][0-9]|30))|(?:(2|02)\/(?:[1-9]|0[1-9]|1[0-9]|2[0-8]))))|(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00)\/(2|02)\/29)$/
      if (!regexDay.test(dateData)) return false
      this.date = dateData.replaceAll('/', '-')
      return true
    },
    // thay đổi nội dung nhập
    inputChange(value) {
      if (!this.checkDayInput(value)) {
        this.$root.$bvToast.toast(
          'Thời gian không hợp lệ',
          {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          },
        )
        if (this.value) {
          const strs = this.value.split('-')
          this.dateFormat = `${strs[2]}/${strs[1]}/${strs[0]}`
        } else {
          this.dateFormat = ''
        }
      }
    },
    // lựa chọn ngày thay đổi
    onContext(ctx) {
      if (ctx.selectedYMD.length > 0) {
        this.dateFormat = ctx.selectedFormatted
      } else {
        this.dateFormat = null
      }
      // emit nếu không phải lần đầu load
      if (!this.isFirst) { this.$emit('input', ctx.selectedYMD) }
      this.isFirst = false
    },
  },
}
</script>
<style lang ="scss" >
.custome-datepicker {
  .btn-outline-primary {
    border: 1px solid #d8d6de !important;
  }
}
.date-picker-format {
  &:focus-within .form-control {
    border-color: #d8d6de !important;
  }
}
</style>

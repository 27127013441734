// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import i18n from '@/libs/i18n'
import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

// format date to DD/MM/YYYY
export const formatDateToDDMM = value => {
  if (!value) return ''
  return moment(String(value)).format('DD/MM/YYYY')
}

// format date to DD/MM/YYYY hh:mm:ss
export const formatDateToDDMMHHMM = value => {
  if (!value) return ''
  return moment(String(value)).format('DD/MM/YYYY HH:ss')
}

// format second to minute or hour
export const formatSecond = value => {
  if (value < 60) return `${value}s`
  if (value >= 60 && value < 3600) {
    let minute = 0
    while (value >= 60) {
      value -= 60
      minute += 1
    }
    return value === 0 ? `${minute}m` : `${minute}m${value}s`
  }
  if (value >= 3600) {
    let minute = 0
    let hour = 0
    while (value >= 60) {
      value -= 60
      minute += 1
    }
    while (minute >= 60) {
      minute -= 60
      hour += 1
    }
    return minute === 0 ? `${hour}h` : `${hour}h${minute}m`
  }
  return ''
}
// get status from start date and end date
export const getStatusFromDate = (startDate, endDate) => {
  const now = moment()
  const start = moment(startDate)
  const end = moment(endDate)
  if ((now - start) < 0) {
    return {
      status: 'QuestionService.ExamUpcoming',
      color: 'warning',
      icon: 'LoaderIcon',
    }
  }
  if ((now - end) > 0) {
    return {
      status: 'QuestionService.ExamEnd',
      color: 'dark',
      icon: 'XSquareIcon',
    }
  }
  return {
    status: 'QuestionService.ExamActive',
    color: 'success',
    icon: 'PlayIcon',
  }
}
// format date to DD/MM/YYYY
export const formatTimeToHHmm = value => {
  if (!value) return ''
  return moment(String(value)).format('HH:mm')
}
// format time moment
export const formatTimeMoment = value => {
  const dateTime = Math.abs(moment(value).utc().diff(moment().startOf('hh'), 'seconds'))

  const minute = 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30
  const year = day * 365

  let fuzzy

  if (dateTime < 30) {
    fuzzy = i18n.t('common.just-then')
  } else if (dateTime < minute) {
    fuzzy = `${dateTime} ${i18n.t('common.seconds')}`
  } else if (dateTime < hour) {
    fuzzy = `${Math.floor(dateTime / minute)} ${i18n.t('common.minute')}`
  } else if (dateTime < day) {
    fuzzy = `${Math.floor(dateTime / hour)} ${i18n.t('common.hours')}`
  } else if (dateTime < day * 2) {
    fuzzy = `${i18n.t('common.yesterday')}`
  } else if (dateTime < week) {
    fuzzy = `${Math.floor(dateTime / day)} ${i18n.t('common.days')}`
  } else if (dateTime < month) {
    fuzzy = `${Math.floor(dateTime / week)} ${i18n.t('common.weeks')}`
  } else if (dateTime < year) {
    fuzzy = `${Math.floor(dateTime / month)} ${i18n.t('common.month')}`
  } else if (dateTime > year) {
    fuzzy = `${Math.floor(dateTime / year)} ${i18n.t('common.year')}`
  }
  return fuzzy
}

// lấy tên theo ngôn ngữ
export const formatFullName = (firstName, lastName) => {
  const lang = localStorage.getItem('lang') === null ? 'vi' : localStorage.getItem('lang')
  switch (lang) {
    case 'vi':
      return `${lastName} ${firstName}`
    case 'en':
      return `${firstName} ${lastName}`
    default:
      return `${lastName} ${firstName}`
  }
}

<!-- =========================================================================================
  File Name: 'UserAvatar.vue'
  Description: 'Avatar chung cho người dùng'
  ----------------------------------------------------------------------------------------
  Author: Thai Nguyen Tan
  Created Date: "2021-03-27"
  Modified Date: "2021-03-27"
========================================================================================== -->
<template lang="">
  <div v-if="isButton!==true">
    <b-avatar
      v-if="checkUrl(data)"
      :src="this.$serverfile + data.avatar"
      :size="size"
    />
    <b-avatar
      v-else
      :variant="getAvatarVariant(data)"
      :size="size"
    >
      <span>{{ getAvatarName(data) }}</span>
    </b-avatar>
  </div>
  <!-- là ảnh đại diện khi chat -->
  <div
    v-else
  >
    <b-avatar
      v-if="checkUrl(data)"
      :src="getAvatarUrl(data)"
      :size="size"
      button
      @click="$emit('click')"
    />
    <b-avatar
      v-else
      variant="primary"
      button
      :size="size"
      @click="$emit('click')"
    >
      <span>{{ getAvatarName(data) }}</span>
    </b-avatar>
  </div>
</template>
<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: _ => ({}),
    },
    size: {
      type: Number,
      default: 32,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      avatarVariants: [
        { name: 'IdentityService_Active', variant: 'light-success' },
        { name: 'IdentityService_Pending', variant: 'light-warning' },
        { name: 'IdentityService_Quitted', variant: 'light-danger' },
        { name: 'IdentityService_Inactive', variant: 'light-secondary' },
      ],
    }
  },
  methods: {
    getAvatarName(data) {
      if (data.firstName && data.lastName) {
        let firstName = 'F'
        if (data.firstName.length > 0) { firstName = data.firstName[0].toUpperCase() }
        let lastName = 'L'
        if (data.lastName.length > 0) { lastName = data.lastName[0].toUpperCase() }
        return `${firstName}${lastName}`
      }
      if (data.name != null) {
        const strs = data.name.split(' ')
        if (strs.length > 1) {
          const firstname = strs[0]
          const lastName = strs[1]
          return firstname[0].toUpperCase() + lastName[0].toUpperCase()
        }
      }
      if (data.userName && data.userName != null) {
        const strs = data.userName.split(' ')
        if (strs.length > 1) {
          const firstname = strs[0]
          const lastName = strs[1]
          return firstname[0].toUpperCase() + lastName[0].toUpperCase()
        }
      }
      return 'FN'
    },
    getAvatarVariant(data) {
      if (this.variant !== null) return this.variant
      if (data.statusName) {
        const variant = this.avatarVariants.find(x => x.name === data.statusName)
        if (variant) return variant.variant
      }
      return 'light-success'
    },
    // Lấy hình ảnh với kích thước nhỏ
    getAvatarUrl(data) {
      // Chuẩn hóa dấu
      data.avatar.replace('\\', '/')
      const arr1 = data.avatar.split('/')
      const fileName = arr1[arr1.length - 1]
      const arr2 = fileName.split('.')
      // Kiểm tra đúng là định dạng của một tệp tin (filename.extension)
      if (arr2.length === 2) {
        arr1[arr1.length - 1] = `64x64.${arr2[1]}`
        return this.$serverfile + arr1.join('/')
      }
      return ''
    },
    checkUrl(data) {
      if (data?.avatar) {
        const arr = data.avatar.split('.')
        if (arr.length > 1) {
        // Độ dài phần mở các tệp tin ảnh thường nhỏ hơn 4
          return arr[arr.length - 1].length < 4
        }
      }
      return false
    },
  },
}
</script>
<style lang="">

</style>
